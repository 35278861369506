import React from "react";
import { Container, Button, Video as VideoPlayer } from "@atoms";

const Video = ({ id, videoUrl, button }) => {
  return (
    <div className="my-10 bg-black" id={id}>
      <Container className="flex flex-col items-center gap-6 py-10 sm:py-16">
        {/* this might get replaced by mux */}
        <VideoPlayer url={videoUrl} autoplay={false} showControls />
        {button?.url && (
          <Button to={button.url} color="viridian" icon="arrow" size="lg">
            {button.text}
          </Button>
        )}
      </Container>
    </div>
  );
};

Video.defaultProps = {};

export default Video;
